import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "New Product Introduction (NPI)",
  "description": "NPI or New Product Introduction is the systematic approach to launching hardware products.",
  "author": "",
  "categories": ["onboarding"],
  "date": null,
  "featured": false,
  "tags": ["services", "nsf", "onboarding"],
  "relatedArticles": ["product-lifecycle", "lifecycle-management", "new-technology-introduction-nti", "nsf"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-is-npi"
    }}>{`What is NPI?`}</h2>
    <p>{`NPI or New Product Introduction is the systematic approach to launching hardware products.`}</p>
    <p>{`NexPCB provides our NPI service to plan your manufacturing strategy against the future unknowns.`}</p>
    <p>{`This includes cost projection, budget management, sourcing, design, manufacturing, assembly, and up to logistics.`}</p>
    <h2 {...{
      "id": "npi-vs-nti"
    }}>{`NPI vs NTI`}</h2>
    <p>{`The main difference between the NTI (New Technology Introduction) and NPI is that NPI refers mostly to the `}<strong parentName="p">{`market entry of products`}</strong>{`.`}</p>
    <p>{`At this stage, it's important for new companies and makers to `}<strong parentName="p">{`navigate through the market`}</strong>{`. As you start producing sales, be cautious of the cost, quality, and time of product delivery. Make sure to also market, and pay attention to the after-sales of the product.`}</p>
    <h2 {...{
      "id": "what-should-you-do-in-npi"
    }}>{`What should you do in NPI?`}</h2>
    <p>{`The most crucial action during this NPI stage is to obtain as much `}<strong parentName="p">{`customer reviews`}</strong>{` available. Their reviews will pinpoint in which areas are your product good at, and at which area are they lacking.`}</p>
    <p>{`Afterward, you'll continue your continuous iteration of the product, to finally obtain the product that satisfies the market. When you notice your sales' growing exponentially, you can finally focus on the other things: `}<strong parentName="p">{`cost optimization`}</strong>{`, which will be assisted by our `}<strong parentName="p">{`lifecycle management`}</strong>{` service.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": ""
        }}><strong parentName="a">{`Lets start working on your project`}</strong></a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      